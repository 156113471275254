<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-mineralgreen">
            <div class="container mx-auto">
                <div class="p-5 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Our Natural Ingredient</h1>
                        <p class="text-white">For us, 100% natural cosmetics means 100% purity. <br> Our ingredients work. They are filled with natural vitamins</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- ABOUT -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="container mx-auto">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">SHEA BUTTER</h1>
                                            <p class="text-mineralgreen mb-2">Moisturizes and nourishes in depth the skin, but also the capillary fibers...</p>
                                            <a href="/page/en/ingredient/1/karite" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img class="" width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-karite.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">HONEY</h1>
                                            <p class="text-mineralgreen mb-2">Absorbs and retains moisture and leaves your skin fresh and supple...</p>
                                            <a href="/page/en/ingredient/2/miel" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-miel.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">AVOCADO</h1>
                                            <p class="text-mineralgreen mb-2">A fruit that is good for the body and also excellent for the epidermis and the  hair fiber...</p>
                                            <a href="/page/en/ingredient/3/avocat" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-avocat.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">ALOE VERA</h1>
                                            <p class="text-mineralgreen mb-2">Ideal soothing active ingredient for the skin. It is a powerful healing...</p>
                                            <a href="/page/en/ingredient/4/aloe-vera" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-aloe-vera.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">CURCUMA</h1>
                                            <p class="text-mineralgreen mb-2">It has several applications: anti-inflammatory, anti-aging and collagen booster...</p>
                                            <a href="/page/en/ingredient/5/curcuma" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-curcuma.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">CUCUMBER</h1>
                                            <p class="text-mineralgreen mb-2">The cucumber has moisturizing, healing, sanitizing and astringent properties...</p>
                                            <a href="/page/en/ingredient/6/concombre" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-concombre.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">COCONUT</h1>
                                            <p class="text-mineralgreen mb-2">Known to be an excellent hair care and also rich in fatty acids...</p>
                                            <a href="/page/en/ingredient/7/coco" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-coco.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">COCOA BUTTER</h1>
                                            <p class="text-mineralgreen mb-2">It is moisturizing, antioxidant and healing. Ideal for the hair...</p>
                                            <a href="/page/en/ingredient/8/beurre-de-cacao" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-beurre-de-cacao.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">SESAME OIL</h1>
                                            <p class="text-mineralgreen mb-2">Sesame oil is rich in vitamin E and vitamin K1...</p>
                                            <a href="/page/en/ingredient/9/huile-de-sesame" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-huile-de-sesame.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/en/Navbar"
import footerbar from "@/views/shared/en/Footer"
import { useMeta } from "quasar"

export default {
    name: "Ingredients",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Nos Ingrédients Naturels', // sets document title
        })
    }
};
</script>